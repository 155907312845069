@import "../../../../styles/settings.scss";

.row{
  margin-bottom: 50px;
  margin-top: 50px;
}
.image-watch{
    width: 250px;
    height: 500px;
     @media (max-width: 768px) {
      width: 200px;
    margin-bottom: 20px;
}
}

.image-button{
    width: 300px;
    height: 500px;
    padding-top: 30px;
     @media (max-width: 768px) {
      width: 250px;
    margin-bottom: 20px;
}
}

.image-station{
    width: 400px;
    height: 400px;
     @media (max-width: 768px) {
      width: 300px;
       height: 300px;
    margin-bottom: 20px;
    margin-top: 20px;
}

}

.col-description{
  margin-left: 50px;
  @media (max-width: 768px) {
    margin-left: 0;
    padding-left: 50px;
    padding-right: 50px;
}
.ul{
    color: var(--input_color);
  margin-left: 20px;
  @media (max-width: 768px) {
     margin-left: 20px;
}
}

}
.product {
  h1 {
  color: #2896af;
  margin: 50px 0 50px 0;
  position: relative;
  font-size: 40px;
  text-align: center;
  }
h2 {
  color: #2896af;
  position: relative;
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
}
}
.product h1::before {
  content: "";
  position: absolute;
  left: 53%;
  bottom: -10px;
  width: 80px;
  height: 2px;
  background: $primary-color;
  transform: translateX(-65px);
  /*    width/2    */
}
.product h2::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 130px;
  height: 2px;
  background: #2896af;
  transform: translateX(-65px);
}

.description-text{
    text-align: justify;
     color: var(--input_color);
}
.produit div > ul {
  font-size: 17px;
  margin-top: 30px !important;
  text-align: justify;
  justify-content: center;
}



/*web application */
.app-presenation{
  display: flex;
   color: var(--input_color);
  @media (max-width: 1200px) {
        display: block;
         margin: auto;
    }

}

.app-presenation, h3 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.first-column{
  flex: 1;
  text-align: left;
  margin-right: 100px;
        @media (min-width: 1200px) {
    margin-left: 10%;
}
  @media (max-width: 1200px) {
    margin-left: 30%;
}
  @media (max-width: 992px) {
    margin-left: 20%;
}
  @media (max-width: 768px) {
    margin-left: 10%;
}
}
.second-column{
  flex: 1;
  text-align: left;
  @media (max-width: 1200px) {
        margin-bottom: 50px;
         margin-left: 30%;

    }
  @media (max-width: 992px) {
         margin-left: 20%;

    }
  @media (max-width: 768px) {
         margin-left: 10%;

    }
}

/*Table*/

.styled-table {
   color: var(--input_color);
  border-collapse: collapse;
  margin-top: 25px;
  font-size: 0.9em;
  min-width: 400px;
   @media (max-width: 768px) {
         min-width: 100px;
    }
}

.styled-table thead th {
  background-color: #2896af;
  color: #ffffff;
  text-align: center;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #b8b8b8;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2896af;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #2896af;
}


 



