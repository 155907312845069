.textfield{
    color: var(--input_color) !important;
}

.textfield_label{
    color: var(--input_color) !important;
}


.textfield_label_red{
    color: #cf0808 !important;
}