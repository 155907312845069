.searchBar-wrap {
    background-color: #f0f0f0;
    width: 30%;
    margin: 2.5rem auto 4rem auto;
    padding: 0.5rem;
    border-radius: 5px;
  }
  
  .searchBar-wrap form {
    display: flex;
    align-items: center;
  }
  
  .searchBar-wrap input {
    background-color: #f0f0f0;
    outline: none;
    border: none;
    flex: 1;
  }
  
  .searchBar-wrap span {
    padding-right: 0.5rem;
    cursor: pointer;
  }
  
  .searchBar-wrap button {
    outline: none;
    border: none;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    background-color: #2896af;
    color: #fff;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    .searchBar-wrap {
      width: 50%;
    }
  }

  @media (max-width: 768px) {
    .searchBar-wrap {
      width: 70%;
    }
  }
  
  @media (max-width: 600px) {
    .searchBar-wrap {
      width: 90%;
    }
  }