
.box img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.box div{
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: transparent;

}

.box div:nth-child(1){
  top: 40%;
  left: 42%;
  animation: animate 14s linear infinite;
}
.box div:nth-child(2){
  top: 70%;
  left: 50%;
  animation: animate 10s linear infinite;
}

.box div:nth-child(3){
  top: 17%;
  left: 25%;
  animation: animate 12s linear infinite;
}
.box div:nth-child(4){
  top: 40%;
  left: 60%;
  animation: animate 20s linear infinite;
}
.box div:nth-child(5){
  top: 67%;
  left: 40%;
  animation: animate 20s linear infinite;
}
.box div:nth-child(6){
  top: 80%;
  left: 70%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(7){
  top: 60%;
  left: 25%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(8){
  background-color: #008aa9;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #008aa933, 0 0 0 10px #008aa944;
  top: 45%;
  left: 70%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(9){
  background-color: #008aa9;
  border-radius: 50%;
  box-shadow: 0 0 0 5px #008aa933, 0 0 0 10px #008aa944;
  top: 80%;
  left: 25%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(10){
  top: 20%;
  left: 50%;
  animation: animate 17s linear infinite;
}

.box div:nth-child(11){
  top: 22%;
  left: 70%;
  animation: animate 11s linear infinite;
}




@keyframes animate {
  0%{
    transform: scale(0) translateY(0) rotate(0);
    opacity: 0.5;
  }

  20%{
    transform: scale(1.1) translateX(40px) rotate(360deg);
    opacity: 0.5;
  }

  40%{
    transform: scale(1.1) translateY(-50px) rotate(0deg);
    opacity: 0.5;
  }

  60%{
    transform: scale(1.1) translateX(-30px) rotate(0deg);
    opacity: 0.5;
  }

  80%{
    transform: scale(1.1) translateY(50px) rotate(0deg);
    opacity: 0.5;
  }

  100%{
    transform: scale(1.1) translateY(-80px) rotate(360deg);
    opacity: 0;
  }
}

.back-trans{
  position: absolute;
  width: 65%;
  height: 100%;
  border-top-left-radius: 90%;
  transition: 0.3s cubic-bezier(.05,.69,1,.23);
  z-index: 1;
  right: 0;
  top: 0;
  background: linear-gradient(to bottom, #008aa930, rgba(214, 214, 214, 0.112)  );
}


.back-trans2{
  position: absolute;
  width: 40%;
  height: 60%;
  border-bottom-right-radius: 90%;
  z-index: 1;
  left: 0;
  top: 0;
  background: linear-gradient(to top, #0046a930, rgba(214, 214, 214, 0.112));
}

.back-trans3{
  position: absolute;
  width: 25%;
  height: 30%;
  border-top-right-radius: 95%;
  z-index: 1;
  left: 0;
  bottom: 0;
  background: linear-gradient(to bottom, #8de2f530, rgba(214, 214, 214, 0.112)  );
}

.global{
  width: 100%;
  margin-top: -50px;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.header-container {
  position:initial;
  width: 50%;
  margin-left: 13%;
  margin-top: 3%;
  text-align: justify;
  float: left;
}

.img-container{
  position: absolute;
  width: 50%;
  right: 0;
  padding-top: 0px;
}

.img-container img{
  width: 100%;

}

.header-container > h2 {
    width: 80%;
    text-align: left;
  color: var(--input_color);
  font-size: 32px;
  margin-bottom: 2%;
 @media screen and (min-width: 960px) {
 .header-container > h2 {
 margin-bottom: 0;
}
}
}

.header-container > h4 {
  width: 60%;
  color: var(--input_color);
  font-size: 18px;
  font-weight: normal;
  // text-align: center;
}


.play-icon {
  color: white;
  position: relative;
  z-index: 5;
  outline: none;
  border: none;
  font-size: 15px;
  background: #008aa9;
  border-radius: 5px;
  transform-origin: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  padding: 17px 30px;
  margin-left: 5%;
  width: auto;
  text-decoration: none;
}

.play{
  margin-top: 50px;
}


.contact{
  color: #008aa9;
  position: relative;
  text-decoration: none;
  font-size: 15px;
  z-index: 5;
  outline: none;
  border: 2px solid #008aa9;
  background-color: transparent;
  border-radius: 5px;
  transform-origin: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  padding: 15px 30px;
  width: auto;
  
}

.play-icon:hover {
  background: transparent;
  border: 2px solid #008aa9;
  cursor: pointer;
  color: #008aa9;
}

.contact:hover{
  color: white;
  cursor: pointer;
  background: #008aa9;
  border: none;
}

@media screen and (max-width: 960px) {
 .header-container > h2 {
    width: 100%;
  font-size: 25px;
  text-align: center;
    margin-left: 10%;
  padding: 0;
}

.header-container{
  width: 90%;
  margin-left: 0px;
  // padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.header-container > h4 {
  margin-top: 30px;
  width: 85%;
  margin-left: 10%;
  font-size: 16px;
  text-align: center;
}

.play{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-left: 10%;
  margin-top: 15%;
}

.img-container{
  display: none;
}

.box img{
  width: 20px;
  height: 20px;
}



}
