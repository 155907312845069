.blogItem-wrap {
  display: flex;
  flex-direction: column;
}
.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 0 20px;
  margin-bottom: 0.5rem;
  z-index: 1;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
  font-size: 0.8rem;
  color: var(--color_blogItem-link);
}

.blogItem-desc {
  position: relative;
  max-height: 60px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
  color: #a9a9a9;
}

.blogItem-desc::before {
  position: absolute;
  content: 'etc...';
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
  font-size: 0.7rem;
  color: var(--color_blogItem-link);
  margin-right: 50px;
}

.blogItem-link:hover{
  color: #2896af;
}

.blogItem-link span{
  transition: 0.3s ease-in-out;
}

.blogItem-link span:hover{
  margin-right: 5px;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
}

.blogItem-author h6 {
  font-size: 0.8rem;
  margin-top: 1rem;
  color:var(--color_blogItem-link);
}

.blogItem-author p {
  font-size: 0.6rem;
  color: #a9a9a9;
  font-weight: 600;
}