.box-img img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @media (max-width: 960px) {
      width: 30px;
      height: 30px;
    }
  }
  
  
  
  .box-img div{
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: transparent;
  
  }
  
  
  
  .box-img div:nth-child(1){
    top: 20%;
    left: 25%;
    animation: animate5 15s linear infinite;
  }
  .box-img div:nth-child(2){
    top: 30%;
    left: 80%;
    animation: animate5 20s linear infinite;
    @media screen and (max-width: 960px) {
      top: 35%;
    }
  
    @media screen and (min-width: 1700px) {
      top: 35%;
    }
  }
  
  
  
  .box-img div:nth-child(3){
    top: 20%;
    left: 60%;
    animation: animate5 15s linear infinite;
  }
  
  .box-img div:nth-child(4){
    top: 40%;
    left: 45%;
    animation: animate5 20s linear infinite;
    @media screen and (max-width: 960px) {
      top: 35%;
    }
  
    
    @media screen and (min-width: 1700px) {
      top: 35%;
    }
  }
  
  
  @keyframes animate5 {
    0%{
      transform: scale(0) translateY(0) rotate(0);
      opacity: 1;
    }
  
    100%{
      transform: scale(1.2) translateY(-300px) rotate(360deg);
      opacity: 0;
    }
  }
  
  .propos-header {
      text-align: center;
      position:initial;
      margin-top: -100px;
      
    }
    
    .propos-header h1 {
      font-size: 3rem;
      color: #0096c7;
      margin-bottom: 1rem;
      margin-top: 60px;
      @media (max-width: 1200px){
        font-size: 40px;
      }
      @media (max-width: 992px){
        font-size: 35px;
      }
      @media (max-width: 768px){
        font-size: 30px;
      }
      @media (max-width: 570px){
        font-size: 28px;
      }
    }
    
    .propos-header h1 span {
      color: #bcc9d9;
    }
    
    .propos-header p {
      color: #a9a9a9;
      font-weight: 500;
      width: 70%;
      text-align: center;
  
      @media (max-width: 1200px){
        font-size: 17px;
      }
      @media (max-width: 992px){
        font-size: 16px;
      }
      @media (max-width: 768px){
        font-size: 14px;
      }
      @media (max-width: 570px){
        font-size: 13px;
      }

      

    }