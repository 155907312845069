.row{
	display: flex;
	flex-wrap: wrap;
    margin-left: 200px;
    @media(max-width: 767px){
        margin-left: 0;
}
}
.footer-col{
    margin-top: 20px;
    min-height: 200px;
   width: 33%;
   padding: 0 15px;
    @media(max-width: 767px){
        min-height: auto;
}
}
.footer-col ul{
	list-style: none;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}

/*responsive*/
@media(max-width: 767px){
  .footer-col{
    width: 100%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}