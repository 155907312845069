.chip {
  font-size: 0.7rem;
  background: #2896af;
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  width: fit-content;
  text-transform: capitalize;
}

.category{
  font-size: 0.7rem;
  background: #2896af;
  color: #fff;
  width: fit-content;
  text-transform: capitalize;
  position: absolute;
  padding: 8px 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin-top: -30px;
  box-shadow: 0 3px 5px #bbb, 0 3px 5px #bbb;
}