.blog-wrap {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 5rem;
  margin-top: 2rem;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.9rem;
  color: var(--color_blogItem-link);
  font-weight: 500;
  padding: 0.4rem 1.5rem;
  border-radius: 5px;
  margin: 1rem;
}

.blog-goBack:hover{
  background-color: #0096c7;
  color: white;
}

.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}
.blog-subCategory > div {
  margin: 1rem;
}
.blog-desc {
  padding: 1rem;
  color: var(--color_blogItem-link);
  margin-top: 1.5rem;
}

.emptyList-wrap {
  display: flex;
  justify-content: center;
}
.emptyList-wrap img {
  max-width: 250px;
  width: 100%;
}
h1{
  color: var(--color_blogItem-link);
}