@import "../../../styles/settings.scss";

.description{
    color: var(--input_color);
}
.col-text{
    width: 600px;
    text-align: justify;
     h2{
        text-align: center;
        margin-bottom: 40px;
        color: var(--color_blogItem-link);
        &::after{
            content: "";
            position: absolute;
            width: 100px;
            height: 5px;
            background: #2896af;
            margin-top: 30px;
            margin-left: -150px;
            border-radius: 10px;
        }
    }
}
    .img-entreprise{
       width: 100%;
    }
.desc-entreprise{

    display: flex;
    margin-bottom: 80px;
    align-items: center;

    .description{
        width: 40%;
        margin-top: 4% ;
        margin-left: 7%;
        h2{
            margin-bottom: 30px;
            color: var(--color_propos-text);
            strong{
                color: #2896af;
                font-weight: bold;
            }
            &::after{
                content: "";
                position: absolute;
                width: 120px;
                height: 4px;
                background: #2896af;
                margin-top: 35px;
                margin-left: -180px;
                border-radius: 10px;
            }
        }
        p{
            text-align: justify;
            word-spacing: 2px;
            line-height: 1.5em;
            color: var(--color_propos-text);
        }
    }
}
.teams {
    h2{
        text-align: center;
        margin-bottom: 30px;
        color: var(--color_blogItem-link);
        &::after{
            content: "";
            position: absolute;
            width: 100px;
            height: 5px;
            background: #2896af;
            margin-top: 45px;
            margin-left: -125px;
            border-radius: 10px;
        }
    }
}
.awards {
    .award-descrip{
        text-align: center;
        margin-bottom: 30px;
        color: var(--color_blogItem-link);
        &::after{
            content: "";
            position: absolute;
            width: 100px;
            height: 5px;
            background: #2896af;
            margin-top: 45px;
            margin-left: -145px;
            border-radius: 10px;
        }
    }
}

@media (max-width:  1200px) {
    .desc-entreprise{
        display: flex;
        margin-bottom: 10%;
        .description{
            width: 44%;
            margin-top: 10%;
            margin-left: -2%;
        }
        h2{
            text-align: center;
        }
        p{
            font-size: 17px;
        }
    }
    .img{
        width: 450px;
        height: 300px;
        margin-left: -11%;
        margin-top: 20%;
    }
}

@media (max-width:  992px) {
    .img-entreprise{
       width: 80%;
       margin-left: 10%;
    }
    .desc-entreprise{
        display: flex;
        margin-bottom: 10%;
        .description{
            width: 48%;
            margin-top: 7%;
            margin-left: -11%;
        }
        h2{
            text-align: center;
        }
        p{
            font-size: 17px;
        }
    }
    .img{
        width: 450px;
        height: 297px;
        margin-left: -20%;
        margin-top: 13%;
    }
}

@media (max-width: 768px) {
    .col-text{
    width: 90%}
    .desc-entreprise{
        display: block;
        margin-bottom: 10px;
        .description{
            width: 92%;
            margin-top: 7%;
            margin-left: 3%;
        }
        h2{
            text-align: center;
           
        }
        p{
            font-size: 16px;
        }
    }
    .img{
        width: 450px;
        height: 300px;
        margin-left: 13%;
    }
}

@media (max-width: 570px) {
    .desc-entreprise{
        display: block;
        margin-bottom: 10px;
        .description{
            text-align: initial;
            margin-top: 7%;
            margin-left: 5%;
        }
        h2{
            text-align: center;
        }
        p{
            font-size: 14px;
        }
    }
    .img{
        width: 350px;
        margin-left: -25px;
    }
}
