@import "../../../../styles/settings.scss";

.problems-visions {
  width: 1200px;
  margin: auto;
  padding: auto;
  margin-bottom: 50px;
}
.text {
  margin-top: 50px;
  margin-bottom: 50px;
}
.problems-visions img {
  width: 450px;
  height: 250px;
}

strong {
  color: #2896af;
  font-weight: bold;
}

.problems-visions h1 {
  color: var(--color_problem-vision);
  margin: 50px 0 50px 0;
  position: relative;
  font-size: 25px;
  text-align: center;
}

.problems-visions h1::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 130px;
  height: 4px;
  border-radius: 10px;
  background: $primary-color;
  transform: translateX(-65px); /*    width/2    */
}
.problems-visions p {
  font-size: 16px;
  margin-top: 30px !important;
  color: var(--color_propos-text);
  text-align: justify;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.problems-visions h2 {
  color: var(--color_problem-vision);
  position: relative;
  font-size: 18px;
  text-align: center;
}

.problems-visions h2::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 130px;
  height: 2px;
  background: var(--color_problem-vision);
  transform: translateX(-65px); /*    width/2    */
}
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 1200px) {
  .row{
    margin: auto;
  }
  .text {
    width: 95%;
    
  }
  .problems-visions {
    width: auto;
    margin-bottom: 0;
  }
  .problems-visions img {
    width: 90%;
  }
}
@media screen and (max-width: 960px) {
  .row{
    margin: auto;
  }
  .text {
    width: 95%;
  }
  .problems-visions {
    width: auto;
    margin-bottom: 0;
  }
  .problems-visions img {
    width: 90%;
  }
}
@media screen and (max-width: 720px) {
  .problems-visions img {
  width: 100%;
  height: 80%;
}
}
