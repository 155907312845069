@import '../../styles/settings.scss';

.navbar.fixe{
    background-color: var(--background_navBar);
    box-shadow:  0px 10px 20px  rgba(0, 0, 0, 0.2);
}

.navbar {
    background-color: transparent;
    height: 80px;
     display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    @media screen and (min-width: 960px) {
        transition: 0.3s  ease-in-out; 
    }
}

.navbar:hover{
    background-color: var(--background_navBar);
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    // max-width: 1500px;
}

.navbar a.active {
    text-decoration: none;
    color: $primary-color;
}


.navbar-logo {
    color: $primary-color;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
}

nav img {
    width: 80px;
}


.nav-menu {
    display: flex;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-translate {
    position: relative;
    top: 20px;
    height: 80px;
}

.nav-links {
    margin-top: 10px;
    color: var(--color_navBar);
    font-size: 16px;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 70%;
    @media screen and (max-width: 1100px) {
       font-size: 14px;
    }
}

.nav-links:hover {
    border-bottom: 4px solid $primary-color;
    border-radius: 10px;
    transition: all 0.2s ease-out;
}



.menu-icon {
    display: none;
}


ul .light {
    display: block;
    /* transform: rotate(360); */
}
.theme{
    position: relative;
    cursor: pointer;
    margin-left: -10px;
    margin-top: -5px;
    width: 35px;
    height: 35px;
    border: 1px solid var(--theme_outline);
    padding: 6.8px 6.5px;
    border-radius: 20%;

}
@media screen and (max-width: 1000px) {
    .theme{
        left: 93%;
        position: absolute;
        top: 30%;
    }
}

@media screen and (max-width: 960px) {
    .theme{
        left: 87%;
        position: absolute;
        top: 30%;
    }
}


@media screen and (max-width: 768px) {
    .theme{
        left: 77%;
        position: absolute;
        top: 30%;
    }
}

@media screen and (max-width: 767px) {
    .theme{
        left: 83%;
        position: absolute;
        top: 30%;
    }
}

@media screen and (max-width: 570px) {
    .theme{
        left: 75%;
        position: absolute;
        top: 30%;
    }
}

@media screen and (max-width: 400px) {
    .theme{
        left: 73%;
        position: absolute;
        top: 30%;
    }
}

.theme:hover{
    background-color: #d4d7d851;
}

.buttonTheme{
    margin-left: 200px;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        margin: 0px;
        padding: 0px;
    }

    .nav-menu.active {
        display: flex;
        background: var(--background_navBar);
        left:0;
        opacity: 1;
        height: auto;
        transition: all 0.5s ease;
                margin: 0px;
        padding: 0px;
        z-index: 0999;
    }

    .nav-links {
        text-align: center;
        width: 100%;
        display: table;
        font-size: 14px;
    }

    .nav-links:hover {
        border-radius: 10px;
        transition: all 0.2s ease-out;
    }

    .navbar-logo {
        position: absolute;
        top: 12px;
        left: 0;
        font-size: 1.2rem;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.7rem;
        cursor: pointer;
    }


    nav img,
    nav img+span {
        width: 60px;
        position: relative;
        top: -35px;
        left: -40px;
    }

}

