.carouselContainer{
    position: relative;
    width: 100%;  
    left: 10%;
    @media (max-width: 570px) {
        left: 8%;
    }

}

.slide{
    overflow: hidden;
}

.carouselTitle{
    text-align: center;
    font-size: 25px;
    margin-top: 30px;
    margin-bottom: 50px;
    &::after{
        content: "";
        position: absolute;
        width: 130px;
        height: 4px;
        background-color: #2896af;
        border-radius: 10px;
        margin-top: 50px;
        margin-left: -220px;
    }
}

.carouselItem img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    @media (max-width: 992px) {
        width: 70px;
        height: 70px;
    }

    @media (max-width: 570px) {
        width: 60px;
        height: 60px;
    }

    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
}

.carouselItem{
    // width: 120px;
    padding: 20px;
    margin-right: 50px;
    transition: 0.3s ease-in-out;
    &:hover{
        transform:translateY(-8px);
        cursor: pointer;
    }
}



