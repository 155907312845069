@import '../../../../styles/settings.scss';


.row-accueil{
    position: relative;
    margin-bottom: 40px;
}

.accueil-service {
    width: 1200px;
    margin: auto;
    padding: auto;
    margin-bottom: 50px;
}

.text-accueil {
    margin-top: 50px;
    
}


.accueil-service h1 {
    color: var(--color_problem-vision);
    margin: 50px 0 50px 0;
    position: relative;
    font-size: 25px;
    text-align: center;
}


.image-dev{
   width: 500px; 
}
.image-logo-design{
   width: 500px; 
}
.image-conception{
   width: 500px; 
}

.accueil-service h1::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 130px;
    height: 4px;
    background: $primary-color;
    transform: translateX(-65px)
        /*    width/2    */
}

.accueil-service p {
    font-size: 17px;
    margin-top: 30px !important;
 color: var(--input_color);
    text-align: justify;
    justify-content: center;
}

.accueil-service h2 {
    color: #2896af;
    position: relative;
    font-size: 25px;
    text-align: center;
}


.accueil-service h2::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    width: 130px;
    height: 2px;
    background: var(--color_problem-vision);
    transform: translateX(-65px)
        /*    width/2    */
}

/* Create two equal column-accueils that floats next to each other */
.column-accueil {
    float: left;
    width: 50%;
}

/* Clear floats after the column-accueils */
.row-accueil:after {
    content: "";
    display: table;
    clear: both;
}

.img3{
    margin-top: -80px;
}

.row2{
    margin-left: 5%;
}

.plus_service button {
    text-decoration: none;
    font-size: 0.9rem;
    margin-top: 20px;
    float: right;
    color: #fff;
    padding-right: 40px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    border: none;
    background-color: #2896af;

    &:hover {
        cursor: pointer;
    }

}

.plus_service.btn2 button {
    float: none;
}

@media (max-width: 1500px) {
    .column-accueil{
        width: 49%;
    }
    .row-accueil{
        margin-left: 5%;
    }
}

@media screen and (max-width: 1200px) {
    .column-accueil {
        width: 90%;
        margin-top: 30px;
        margin-left: 5%;
        img{
            width: 100%;
            margin-left: 25%;
        }
    }
    .column-accueil.ligne2 {
        position: absolute;
        margin-left: -5%;
        margin-bottom: 100px;
        margin-top: 10%;
    }

    .column-accueil.text-accueil {
        width: 85%;
        position: relative;
        margin-left: 6%;
    }

    .column-accueil.texte2 {
        margin-top: 460px;
    }

    .accueil-service {
        width: auto;
        padding: 30px;
        margin-bottom: 0;
    }

    .accueil-service img {
        width: 50%;
    }
    .plus_service button{
        position: absolute;
        margin-left: 45%;
    }

    .texte3{
        margin-bottom: 100px;
    }
}



@media (max-width : 992px) {
   
    .column-accueil {
        width: 70%;
        margin-left: 10%;
        img{
            width: 100%;
            margin-left:2%;
        }
    }

    .plus_service button {
        float: none;
        margin-left: 42%;
        margin-bottom: 30px;
    }
    .column-accueil.ligne2 {
        position: absolute;
        margin-left: 10%;
        margin-bottom: 100px;
        margin-top: 15%;
    }

    .column-accueil.texte2 {
        margin-top: 530px;
    }

    .column-accueil.text-accueil{
        position: relative;
        width: 100%;
        margin-left: -5%;
    }

}

@media (max-width : 768px) {
        .accueil-service h1 {
    margin: 0 0 0 0;
}
    .column-accueil {
        width: 70%;
        margin-left: -5%;
        img{
            width: 150%;
            margin-left: -20%;
        }
    }
    
    .column-accueil.ligne2 {
        position: absolute;
        margin-left: 3%;
        margin-top: 100px;
    }

    .column-accueil.text-accueil {
        position: relative;
        width: 100%;
        margin-left: -5%;
        p{
            font-size: 15px;
        }
    }

        .column-accueil.texte2 {
            margin-top: 430px;
        }

    .plus_service button {
        margin-left: 42%;
    }

}


@media (max-width: 570px) {
    .column-accueil{
        width: 70%;
        margin-left: 10%;  
        margin-top: 50px;  
    }

    .column-accueil.ligne2{
        margin-left: 13%;
        position: absolute;
    }
  
    .column-accueil.text-accueil{
        position: relative;
        width: 100%;
        margin-left: -3%;
    }

    .column-accueil.texte2{
        margin-top: 350px;
    }

    .plus_service button {
        margin-left: 37%;
    }

}