@import '../../../../styles/settings.scss';



.gallery_title {
    color: var(--color_problem-vision);
    margin: 50px 0 50px 0;
    position: relative;
    font-size: 25px;
    text-align: center;
    &::after {
        content: "";
        position: absolute;
        width: 80px;
        height: 4px;
        background-color: #2896af;
        border-radius: 10px;
        margin-top: 40px;
        margin-left: -90px;
    }
}



.text-wrap{
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
    position: absolute;
    margin-top: -180px;
    padding: 5%;

    @media (max-width: 992px) {
        margin-top: -150px;
    }

    @media (max-width: 768px) {
        margin-top: -120px;
    }

    @media (max-width: 570px) {
        margin-top: -80px;
    }
    .name{
       text-align: justify;
       color: #008aa9;
       font-size: 40px;
       font-weight: bold;

        @media (max-width: 992px) {
            font-size: 30px;
        }
       
        @media (max-width: 768px) {
            font-size: 20px;
        }

        @media (max-width: 570px) {
            font-size: 15px;
        }
    }
    .caption p{
        display: flex;
        justify-content: justify;
        text-align: center;
        color: #fff;
        width: 100%;
        // padding: 2%;
        font-size: 25px;


        @media (max-width: 992px) {
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 15px;
        }

        @media (max-width: 570px) {
            font-size: 10px;
        }
    }
}

