
.realisation{
    margin-bottom: 50px;
    background: linear-gradient(to right, #2896af, #94e2f4);
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 110px;
    padding-left: 0px;
   .element{
        display: flex;
        justify-content: center;
        .element_realisation{
            margin-left: 130px;
            color: #fff;
            .increment{
                text-align: center;
                color: #fff;
                margin-bottom: 10px;
                font-size: 50px;
                @media (max-width: 1200px){
                    font-size: 40px;
                }
            }

            .desc_increment{
                font-weight: normal;
            }
        }
    }
}

@media (max-width: 1500px) {
    .desc_increment{
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .element{
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        .increment{
            font-size: 20px;
        }
    }
    .desc_increment {
        font-size: 12px;
    }
}

@media (max-width: 992px) {
    .desc_increment {
        font-size: 13px;
    }
}

@media (max-width: 770px) {
    .element {
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        .element_realisation {
            margin-bottom: 50px;
            margin-left: 0px;
            .increment {
                text-align: center;
                color: #fff;
                margin-bottom: 10px;
                font-size: 10px;
            }
    
            .desc_increment {
                text-align: center;
                font-weight: normal;
                font-size: 25px;
            }
        }
    }


}