:root{
    --background_navBar_light: white; 
    --background_navBar_dark: #010f2b; 
    --color_navBar_light: #000;
    --color_navBar_dark: #fff;
    --theme_outline_light: #c5c7c8;
    --theme_outline_dark: #2896af;
    --theme_hover_light: #d4d7d851;
    --theme_hover_dark: #62d8f013;
    --problem-vision_background_light: white;
    --problem-vision_background_dark: #031532fa;
    --color_problem-vision_light: #000; 
    --color_problem-vision_dark: #fff; 
    --input_boderColor_light: #a6a7a751;
    --input_boderColor_dark: #fff;
    --input_hover_light: #000;
    --input_hover_dark: #069afc;
    --input_color_light: #000;
    --input_color_dark: #fff;
    --input_focus_light: #a6a7a751;
    --input_focus_dark: #0468ab;
    --color_footer_link_light: #566573;
    --color_footer_link_dark: #fff;
    --background_contact_light: #84d8eb40;
    --background_contact_linear_light: rgba(139, 135, 135, 0.247);
    --background_contact_dark: #84d8eb40;
    --background_contact_linear_dark: rgba(139, 135, 135, 0);
    --color_blogItem-link_light:black;
    --color_blogItem-link_dark:white;
    --border_icon_service_light: #fff;
    --border_icon_service_dark: #031532fa;
    --background_service_light: #e6f7fb;
    --background_service_dark: #fff;
    --text_service_light: #000;
    --text_service_dark: #000;
    --background_service-logo_light: rgba(169, 230, 239);
    --background_service-logo_dark: #fff;
    --background_service-h4-after-before_light:  #2896af;
    --background_service-h4-after-before_dark:  #031532fa;
    --color_propos-text_light: #000;
    --color_propos-text_dark: #c5c7c8;
}

.light-theme{
    --background_navBar: var(--background_navBar_light);
    --color_navBar: var(--color_navBar_light);
    --theme_outline: var(--theme_outline_light);
    background-color: var(--problem-vision_background_light);
    --color_problem-vision: var(--color_problem-vision_light);
    --input_borderColor: var(--input_boderColor_light);
    --input_hover: var(--input_hover_light);
    --input_color: var(--input_color_light);
    --input_focus: var(--input_focus_light);
    --color_footer_link:var(--color_footer_link_light);
    --background_contact: var(--background_contact_light);
    --background_contact_linear: var(--background_contact_linear_light);
    --color_blogItem-link: var(--color_blogItem-link_light);
    --border_icon_service: var(--border_icon_service_light);
    --background_service: var(--background_service_light);
    --text_service: var(--text_service_light);
    --background_service-logo: var(--background_service-logo_light);
    --background_service-h4-after-before: var(--background_service-h4-after-before_light);
    --color_propos-text: var(--color_propos-text_light);
    --main-color: #0096c7;
    --color-text: #151515;
}

.dark-theme{
    --background_navBar: var(--background_navBar_dark);
    --color_navBar: var(--color_navBar_dark);
    --theme_outline: var(--theme_outline_dark);
    background-color: var(--problem-vision_background_dark);
    --color_problem-vision: var(--color_problem-vision_dark);
    --input_borderColor: var(--input_boderColor_dark);
    --input_hover: var(--input_hover_dark);
    --input_color: var(--input_color_dark);
    --input_focus: var(--input_focus_dark);
    --color_footer_link: var(--color_footer_link_dark);
    --background_contact: var(--background_contact_dark);
    --background_contact_linear: var(--background_contact_linear_dark);
    --color_blogItem-link: var(--color_blogItem-link_dark);
    --border_icon_service: var(--border_icon_service_dark);
    --background_service: var(--background_service_dark);
    --text_service: var(--text_service_dark);
    --background_service-logo: var(--background_service-logo_dark);
    --background_service-h4-after-before: var(--background_service-h4-after-before_dark);
    --main-color: #0096c7;
    --color-text: #151515;;
    --color_propos-text: var(--color_propos-text_dark)
} 