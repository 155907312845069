.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.main {
    width: 380px;
    min-height: 500px;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;
    margin-right: 50px;
}

.service {
    background: var(--background_service);
    padding: 30px;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    margin-bottom: 1%;
    height: 350px;
    z-index: 1;
    box-shadow: 0 0 20px -15px #000;
    transition: transform .5s;
}

.service p {
    color: var(--text_service);
}

.service-logo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: -90px auto 0;
    background: var(--background_service);
    border: 12px solid var(--border_icon_service);
}

.service-logo img {
    width: 60px;
    margin-top: 5px;
    align-items: center;
    transition: transform 1s;
}

.service h4 {
    height: 30px;
    width: 80%;
    background: var(--background_service);
    margin: 50px auto;
    position: relative;
    font-weight: bold;
}

.service h4::after {
    content: '';
    width: 40px;
    height: 30px;
    background: linear-gradient(to right, #fff, var(--background_service-h4-after-before));
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: -1;
}

.service h4::before {
    content: '';
    width: 40px;
    height: 30px;
    background: linear-gradient(to right, var(--background_service-h4-after-before), #fff);
    position: absolute;
    left: -5px;
    bottom: -5px;
    z-index: -1;
}

.shadowOne {
    height: 200px;
    width: 80%;
    margin-bottom: 4px;
    position: absolute;
    border-radius: 10px;
    background: var(--background_service);
    bottom: 0;
    left: 10%;
    z-index: 0;
    transition: transform .5s;
}

.shadowTwo {
    height: 200px;
    width: 64%;
    border-radius: 10px;
    margin-bottom: 14px;
    bottom: 0;
    left: 18%;
    z-index: -0.9;
    background: var(--background_service);
    position: absolute;
    transition: transform .5s;
}

.main:hover .service {
    transform: translateY(-35px);
}

.main:hover .shadowOne {
    transform: translateY(-25px);
}

.main:hover img {
    transform: rotate(360deg);
}

@media (max-width: 1200px) {

    .design {
        margin-left: 12%;
    }

    .desktop {
        margin-left: 6%;
    }
}

@media (max-width: 1000px) {

    .design {
        margin-left: 8%;
    }

    .desktop {
        margin-left: 4%;
    }
}


@media (max-width: 992px) {

    .main {
        margin-right: 0;
    }
    .design {
        margin-left: 3%;
    }

    .desktop {
        margin-left: 1%;
    }
}

@media (max-width: 768px) {
    .main {
            width: 360px;
    }
    .row {
        margin-left: 20%;
    }

    .design {
        margin-left: 2%;
    }
        .service {
            margin-left: 50px;
            margin-right: 50px;
            height: 420px;
        }
}

@media (max-width: 570px) {
    .row {
        margin-left: 10%;
    }

    .design {
        margin-left: 2%;
    }
}