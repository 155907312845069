@import "../../../../styles/settings.scss";

.title h1 {
  color: var(--color_problem-vision);
  margin: 50px 0 50px 0;
  position: relative;
  font-size: 25px;
  text-align: center;
}

.title h1::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 130px;
  height: 4px;
  border-radius: 10px;
  background: $primary-color;
  transform: translateX(-65px); /*    width/2    */
}

.technology-logo{
    width: 100px;
}

.technology{
    margin: 20px;
}
