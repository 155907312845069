.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}

.ag-container-shops {
  padding-bottom: 80px;
  overflow: hidden;
}
.ag-card-bg {
  height: 100%;
  width: 200%;
  background-position: 50%;
  background-size: cover;

  position: absolute;
  top: 0;
  left: -50%;

  -webkit-transition: height 0.6s;
  -moz-transition: height 0.6s;
  -o-transition: height 0.6s;
  transition: height 0.6s;
}
.ag-shop-card_box-wrap {
  padding: 15px;
}
.ag-shop-card_box {
  height: 380px;
  max-width: 300px;
  overflow: hidden;

  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, 0.25);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, 0.25);
  -o-box-shadow: 0 10px 20px 0 rgba(0, 0, 35, 0.25);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 35, 0.25);

  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
.ag-shop-card_body {
  display: block;
  height: 250px;
  width: 300px;
  background-position: 50%;
  background-size: cover;

  overflow: hidden;

  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;

  position: relative;
}
.ag-shop-card-body_link {
  height: 100px;
  width: 100px;
  margin: 0 auto;

  overflow: hidden;

  z-index: 9;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  -o-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.35);

  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);

  -webkit-transition: top 0.4s;
  -moz-transition: top 0.4s;
  -o-transition: top 0.4s;
  transition: top 0.4s;
}
.ag-shop-card-body_img {
  max-width: 100%;
}
.ag-shop-card_footer {
  padding: 15px 30px;
  border: 0;
  background-color: #fff;

  position: relative;
}
.ag-shop-card-footer_title {
  display: block;
  line-height: 1.3;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #690911;
  text-align: center;
}
.ag-shop-card-footer_products {
  display: block;
  text-align: center;
  line-height: 1.3;

  font-size: 12px;
}
.ag-shop-card-footer_link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: #ff7b1a;

  position: absolute;
  right: 30px;
  top: 15px;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.ag-shop-card-footer_arrow {
  max-width: 16px;
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}

@media only screen and (max-width: 639px) {
}

@media only screen and (max-width: 479px) {
}

@media (min-width: 768px) and (max-width: 979px) {
  .ag-format-container {
    width: 750px;
  }
}

@media (min-width: 980px) and (max-width: 1161px) {
  .ag-format-container {
    width: 960px;
  }
}