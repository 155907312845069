.bouton{
    width: 40px;
    position: fixed;
    right: 25px;
    background: #008aa9;
    padding: 10px;
    bottom: 70px;
    border-radius: 20%;
    display: none;
    z-index: 999;
    @media (max-width: 1200px) {
        width: 35px;
        padding: 9px;
    }
    @media (max-width: 992px) {
        width: 32px;
        padding: 7px;
    }
    @media (max-width: 768px) {
        width: 28px;
        padding: 6px;
    }
    @media (max-width: 570px) {
        width: 25px;
        padding: 5px;
    }
}
html{
    scroll-behavior: smooth;
}
